<template>
    <div class="copy-dialog-box">
        <transition name="copyDialog" appear>
            <div class="copy-dialog">
                <div class="title-box">
                    <div></div>
                    <div class="title-text">
                        邀请链接已复制
                    </div>
                    <img src="@/assets/index/close_dialog.svg" @click="closeDialog">
                </div>
                <div class="code-input-box">
                    <div>
                       {{imageInfo.codeUrl}}
                    </div>
                </div>
                <div class="share-btn-box">
                    <div class="share-btn" @click="shareToWechatByDialog">
                        {{'去微信分享给好友('+ shareCountdown+'s)'}}
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        props:{
            imageInfo:Object,
            shareCountdown:Number
        },
        data() {
            return {
                
            }
        },
        mounted() {
          this.startCounDown() 
        },
        methods:{
            shareToWechatByDialog() {
                this.$emit('shareToWechatByDialog')
            },
            startCounDown() {
                this.$emit('startCounDown')
            },
            closeDialog() {
                this.$emit('closeDialog')
            }
        }
    }
</script>

<style scoped>
    .copy-dialog-box {
        position: fixed;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .copyDialog-enter,
    .copyDialog-leave-to,
    .copyDialog-enter {
        opacity: 0;
        transform: scale(0);
    }
    
    .copyDialog-enter-to,
    .copyDialog-leave {
        opacity: 1;
        transform: scale(1);
    }
    
    .copyDialog-enter-active,
    .copyDialog-leave-active {
        transition: 0.3s;
    }
    .copy-dialog {
        width: 175px;
        padding: 14px 20px;
        background: #FFFFFF;
        border-radius: 8.75px;
    }
    .title-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 4px;
        padding-bottom: 13.75px;
    }
    .title-box>img {
        width: 7px;
    }
    .title-text {
        flex: 1;
        text-align: center;
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
    }
    .code-input-box {
        width: 100%;
        padding: 0 14px;
        display: flex;
        align-items: center;
        height: 24px;
        background: #F4F4F4;
    }
    .code-input-box>div {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 8.75px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
    }
    .share-btn-box {
        padding-top: 16.25px;
    }
    .share-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        height: 23.75px;
        background: #5FCF73;
        border-radius: 12px;
    }
</style>