<template>
    <div style="padding: 0; overflow-y: scroll;">
        <Horizontal ref="Horizontal"
        :imageInfo="imageInfo"
        :methodsList="methodsList"
        @createQr="createQr"
        @saveImage="saveImage"
        @operationPicture="operationPicture"
        @backToIndex="backToIndex"
         v-if="$store.getters.currentPageVersion === 'Horizontal'"></Horizontal>

        <Vertical ref="Vertical"
        :imageInfo="imageInfo"
        :methodsList="methodsList"
        @createQr="createQr"
        @saveImage="saveImage"
        @operationPicture="operationPicture"
        @backToIndex="backToIndex"
        v-else></Vertical>
        
        <CopyLinkDialogHorizontal 
        :imageInfo="imageInfo"
        :shareCountdown="shareCountdown"
        @shareToWechatByDialog="shareToWechatByDialog"
        @startCounDown="startCounDown"
        @closeDialog="closeDialog"
        v-if="$store.getters.currentPageVersion === 'Horizontal'&&isShowLinkDialog"></CopyLinkDialogHorizontal>
        <CopyLinkDialogVertical 
        :imageInfo="imageInfo"
        :shareCountdown="shareCountdown"
        @shareToWechatByDialog="shareToWechatByDialog"
        @startCounDown="startCounDown"
        @closeDialog="closeDialog"
        v-if="$store.getters.currentPageVersion === 'Vertical'&&isShowLinkDialog"></CopyLinkDialogVertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Index/InviteIndex/Horizontal/InviteImage.vue'
    import Vertical from '@/components/Index/InviteIndex/Vertical/InviteImage.vue'
    import CopyLinkDialogHorizontal from '@/components/Index/InviteIndex/Horizontal/CopyLinkDialog.vue'
    import CopyLinkDialogVertical from '@/components/Index/InviteIndex/Vertical/CopyLinkDialog.vue'
    
    import html2canvas from "html2canvas"; //引入组件
    import QRCode from "qrcodejs2"; //二维码生成组件
    
    import wechatIcon from '@/assets/index/invite_image/invite_image_wechat.svg'
    import linkIcon from '@/assets/index/invite_image/invite_image_link.svg'
    import saveIcon from '@/assets/index/invite_image/invite_image_save.svg'
    export default {
        components: {
            Horizontal,
            Vertical,
            CopyLinkDialogHorizontal,
            CopyLinkDialogVertical
        },
        data() {
            return {
                imageInfo:{
                    codeUrl:'',
                    imageUrl:'',
                    inviteCode:''
                },
                qrcode: "", //生成的二维码对象,
                posterImg: "", //合成好的base64图片地址
                methodsList:[
                    {
                        title:'微信好友',
                        icon:wechatIcon,
                        code:'wechatIcon'
                    },
                    {
                        title:'保存图片',
                        icon:saveIcon,
                        code:'saveIcon'
                    },
                    {
                        title:'复制链接',
                        icon:linkIcon,
                        code:'linkIcon'
                    }
                ],
                isShowLinkDialog:false,
                shareCountdown:3,
                shareCdTimer:null
            }
        },
        created() {
            // console.log(2222222222222,this.$route.params.imageInfo)
            this.imageInfo = this.$route.params.imageInfo
            //test
            // this.imageInfo.codeUrl = 'https://baidu.com'
        },
        methods: {
            backToIndex() {
                console.log('dhvbdj')
               this.$router.push({
                   name:'InviteIndex'
               }) 
            },
            createQr(domItem) {
                //生成二维码
                this.qrcode = new QRCode(domItem.codeRoom, {
                    width: 72, // 二维码宽度，单位像素
                    height: 72, // 二维码高度，单位像素
                    text: this.imageInfo.codeUrl, // 生成二维码的链接
                    correctLevel: QRCode.CorrectLevel.M //降低容错级别
                }); 
                this.synthesisImg(domItem.saveRoom).then((canvas) => {
                    this.posterImg = canvas.toDataURL("image/png");
                }).catch(err=> {
                })
            },
            operationPicture(type) {
                switch (type) {
                    case 'wechatIcon':
                        this.shareToWechat()
                        break;
                    case 'saveIcon':
                        this.saveImage()
                        break;
                    case 'linkIcon':
                        this.$common.copyText(this.imageInfo.codeUrl, '邀请码')
                        break;
                }
            },
            //分享到微信
            shareToWechat() {
                if(this.$common.copyText(this.imageInfo.codeUrl, '邀请码', 'invite')) {
                    this.shareCountdown = 3
                    this.isShowLinkDialog = true
                }
            },
            //开启分享倒计时
            startCounDown() {
                let that = this
               this.shareCdTimer = setInterval(()=> {
                   console.log(that.shareCountdown)
                   that.shareCountdown = that.shareCountdown - 1
                   if(that.shareCountdown<1) {
                       that.shareToWechatByDialog()
                   }
               },1000) 
            },
            shareToWechatByDialog() {
                clearInterval(this.shareCdTimer)
                this.shareCdTimer = null
                switch (this.$common.getNowSystem()) {
                    case 'ios':
                        this.$common.postMessageToIos('shareToWechat')
                        break;
                    case 'Android':
                        android.shareToWechat()
                        break;
                    case 'web':
                        top.location.href = 'weixin://'
                        break;
                }
            },
            //关闭弹窗
            closeDialog() {
                clearInterval(this.shareCdTimer)
                this.shareCdTimer = null
                this.isShowLinkDialog = false
            },
            synthesisImg(domItem) {
                return html2canvas(domItem, {
                    useCORS: true, //这个为true代表允许跨域
                    allowTaint: false,
                    logging: false,
                    letterRendering: true,
                })
                
            },
            //合成的图片保存到本地
            saveImage() {
                const nativeNeedImage = this.posterImg.replace('data:image/png;base64,', ''),
                      finalImage = JSON.stringify([nativeNeedImage])
               switch (this.$common.getNowSystem()) {
                   case 'ios':
                       this.$common.postMessageToIos('saveImage', finalImage)
                       break;
                   case 'Android':
                       android.saveImage(finalImage)
                       break;
                   case 'web':
                       let a = document.createElement("a"); // 生成一个a元素
                       a.download = "邀请海报"; // 设置图片名称
                       a.href = this.posterImg; // 将生成的URL设置为a.href属性
                       a.click(); // 触发a的单击事件
                       break;
               }
            },
        }
    }
</script>

<style>
</style>