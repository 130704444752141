<template>
    <div class="invite-image-box">
        <img src="@/assets/index/invite_image/invite_image_close.svg" class="close-icon" @click="backToIndex">
       <div class="image-box">
           <div class="save-room" ref="saveRoom">
               <div class="code-img-box" >
                   <div class="code-img" >
                       <div ref="cavansbox"></div>
                   </div>
               </div>
           </div>
       </div>
       <div class="bottom-btn-box">
           <div class="bottom-btn-item" v-for="(item, index) in methodsList" :key="index" @click="operationPicture(item.code)">
               <div class="bottom-image-box">
                   <img :src="item.icon">
               </div>
               <div>
                   {{item.title}}
               </div>
           </div>
       </div>  
    </div>
</template>

<script>
   
    export default {
        props:{
            imageInfo:Object,
            methodsList:Array
        },
        data() {
            return {
                isCreatedImage:false//
            }
        },
        created() {
            let that = this,
                watingForDom = setInterval(() => {
                    if (that.$refs.cavansbox) {
                        clearInterval(watingForDom)
                        that.createQr()
                    }
                }, 100)
        
        },
        methods:{
            createQr() {
                this.$emit('createQr', {saveRoom:this.$refs.saveRoom, codeRoom:this.$refs.cavansbox})
            },
            //点击下方按钮
            operationPicture(type) {
                this.$emit('operationPicture', type)
            },
            //合成的图片保存到本地
            saveImage() {
               this.$emit('saveImage')
            },
            //返回邀请首页
            backToIndex() {
                this.$emit('backToIndex')
            }
        }
    }
</script>

<style scoped>
    .invite-image-box {
        position: relative;
        width: 100%;
        min-height: 100%;
        background-color: #131417;
    }
    .image-box {
        padding: 2px;
    }
    .save-room {
        position: relative;
        width: 100%;
        height: 280px;
        background-image: url('../../../../assets/index/invite_image/invite_image_back.png');
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-color: #131417;
    }
    .code-img-box {
        width: 54px;
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 26.25px;
        bottom: 8px;
        background-color: #ffffff;
    }
    .code-img {
        width: 52px;
        height: 52px;
       border: 1px solid black;
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .close-icon {
        width: 14px;
        position: absolute;
        top: 3.75px;
        right: 3.75px;
        z-index: 10;
    }
    .bottom-btn-box {
        padding: 8.75px 12.5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bottom-btn-item {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    .bottom-image-box {
        padding-bottom: 10px;
    }
    .bottom-image-box>img {
        width: 29px;
    }
    .bottom-btn-item>div {
        font-size: 8px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }
</style>